<template>
  <div>
    <el-dialog
      :title="title"
      :visible.sync="isShow"
      width="30%"
      :before-close="handleClose"
      center
    >
      <div class="content">
        <PassWordInput
          ref="PassWordInput"
          @inputFinish="inputFinish"
          class="password-box"
        ></PassWordInput>
        <p v-if="tip" style="color: red">{{ tip }}</p>
        <el-button @click="submit" :disabled="istrue">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>

export default {
  data() {
    return {
      password: "",
      tip: "",
      istrue: false,
    };
  },
  props: {
    isShow: {
      type: Boolean,
    },
    title: {
      type: String,
      default: "输入密码",
    },
    config: {
      typeof: Object,
    },
  },
  components: {
    PassWordInput: () => import("./PassWordInput.vue"),
  },
  methods: {
    clearPassword() {
      console.log(111);
      this.$refs.PassWordInput.msg = "";
    },
    handleClose() {
      this.$refs.PassWordInput.msg = "";
	  this.tip = "";
      this.$emit("close");
    },
    inputFinish(e) {
      this.password = e;
    },
    submit() {
      if (this.password.length < 6) {
        this.tip = "请输入密码";
        return;
      }
      // this.config.password=md5(this.password)
      // withdraw(this.config).then((res) => {
      //   if (res.code == 0) {
      //     this.$message({
      //       type: "success",
      //       message: "提现申请成功",
      //     });
      //     this.$emit('func',res.code)
      //   }else{
      //      this.$message(res.message);
      //   }
      // });
      this.$emit("personnelStructure",this.password)
      this.$emit("submits",this.password)
      this.handleClose();
      this.$emit("submit", this.password);
      this.istrue = true;
      setTimeout(() => {
        this.istrue = false;
        this.$refs.PassWordInput.msg = "";
      }, 3000);
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  text-align: center;
  .password-box {
  }
  .el-button {
    margin-top: 20px;
  }
}
</style>